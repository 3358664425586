import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import CodeDetail from "../../../components/Drive/Code/detail.js"
import ReduxWrapper from "../../../redux/reduxWrapper"

const CodeDetails = props => {
  return <PrivateRoute component={CodeDetail} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<CodeDetails {...props} />} />
export default WrappedPage
