import React, { useEffect, useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import CodeEditor from "@uiw/react-textarea-code-editor"
import { Link } from "gatsby"
import { Form } from "react-bootstrap"

import Layout from "../../../components/Layout/layout"
import Seo from "../../seo"
import InstallationOptions from "../Doc/InstallationOptions"
import MoreOptions from "./moreOptions"

import "../../../css/datepicker.css"
import "../../../css/bootcomplete.css"
import "../../../css/swiftcloud_global.css"
import "../../../css/fileinput.css"
import "../../../css/chat-dock.css"
import "../../../css/edocs.css"
import "bootstrap-tagsinput/src/bootstrap-tagsinput.css"
import "bootstrap-select/dist/css/bootstrap-select.css"
import CodeModal from "../Popups/codeModal"
import AimoModal from "./aimoModal"
import AxiosInstance from "../../../services/axiosInstance"
import { getUserName } from "../Doc/Service/docHelper"
import PanelToggle from "../Item/panelToggle"

const Detail = ({ selectedDocId }) => {
  const [code, setCode] = useState(""),
    [loadDocDetails, setLoadDocDetails] = useState(true),
    [codeName, setCodeName] = useState(""),
    [ownerId, setOwnerId] = useState(""),
    [driveId, serDriveId] = useState(""),
    [driveType, setDriveType] = useState(""),
    [parentDoc, setParentDoc] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [docPrivacy, setDocPrivacy] = useState(""),
    [whoCanModify, setWhoCanModify] = useState(""),
    [privacyPassword, setPrivacyPassword] = useState(""),
    [whoCanCopy, setWhoCanCopy] = useState(""),
    [saveLoading, setSaveLoading] = useState(false),
    [aimoShow, setAimoShow] = useState(),
    [fileUrl, setFileUrl] = useState("")

  const [states, setStates] = useState({
    driveType: "code",
    showInstallationPanel: false,
    showMoreDetailsPanel: false,
  })

  const aimoModalShow = () => setAimoShow(true)

  const getDocData = async selectedDocId => {
    await AxiosInstance.get(`/drive/code/detail/${selectedDocId}`).then(response => {
      fillDocData(response.data.code)
    })
  }

  const fillDocData = docDetail => {
    setCodeName(docDetail.docName)
    setOwnerId(docDetail.driveAll.ownerID)
    setParentDoc(docDetail.parentDoc)
    setCode(docDetail.content)
    serDriveId(docDetail.driveAll.id)
    setDriveType(docDetail.driveAll.type)
    setFileUrl(docDetail.driveAll.fileURL)
    setTempTags(docDetail.driveForm.moreOptions.doc_private_tags ?? [])
    setTags(docDetail.driveForm.moreOptions.doc_private_tags ?? [])
  }

  useEffect(() => {
    if (loadDocDetails) {
      setLoadDocDetails(false)
      getDocData(selectedDocId).then(r => r)
    }
  })

  const submitDoc = e => {
    e.preventDefault()
    if (saveLoading) {
      return false
    }
    setSaveLoading(true)
    const formData = new FormData(e.target)
    let data = {
      userName: window.localStorage.getItem("userName"),
      docName: formData.get("codeName"),
      content: code,
      ownerID: formData.get("ownerID"),
      parentDoc: formData.get("parentDoc"),
      form: {
        moreOptions: {
          doc_type: formData.get("docType"),
          esign_mode: formData.get("esign_mode"),
          pdf_generation: formData.get("pdf_generation"),
          authentication: formData.get("authentication"),
          doc_private_tags: tempTags,
          doc_privacy: formData.get("doc_privacy"),
          who_can_modify: formData.get("who_can_modify"),
          privacy_password: formData.get("privacy_password"),
          who_can_copy: formData.get("who_can_copy"),
          copy_password: formData.get("copy_password"),
          update_data: formData.get("update_data"),
          email_verification: formData.get("email_verification"),
          send_sms_verification: formData.get("send_sms_verification"),
          sign_in_fg: formData.get("sign_in_fg"),
          upload_gov_id: formData.get("upload_gov_id"),
          geo_location: formData.get("geo_location"),
          photo_signor: formData.get("photo_signor"),
          language: formData.get("language"),
          doc_print: formData.get("doc_print"),
          font_size: formData.get("font_size"),
          doc_comments: formData.get("doc_comments"),
          esign_save_data: formData.get("esign_save_data"),
          append_doc: formData.get("append_doc"),
          append_upload: formData.get("append_upload"),
          apply_stamp: formData.get("apply_stamp"),
          comments_visibility: formData.get("comments_visibility"),
          output_pdf_file_type: formData.get("output_pdf_file_type"),
        },
      },
    }
    saveCodeData(data).then(r => r)
  }

  const saveCodeData = data => {
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/code/edit/${selectedDocId}`, data)
        .then(function (response) {
          setSaveLoading(false)
          console.log(response.data)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }

  return (
    <Layout>
      <Seo title={codeName} />
      <Form onSubmit={submitDoc} id={"codeForm"}>
        <div id="right-section" className="h-100">
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row g-0">
                <div className="col-lg-8 col-sm-12 mb-3">
                  <div className="pageTitle_Editor">
                    <Link to="/drive" className="goBack">
                      <i className="bi bi-arrow-90deg-left" />
                    </Link>
                    <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
                      <i className="bi bi-code-slash" />
                    </button>
                    <Form.Control
                      type="text"
                      className="form-control"
                      placeholder="Title / Summary"
                      name="codeName"
                      defaultValue={codeName}
                    />
                    <Form.Control type="hidden" defaultValue={ownerId} name="ownerID" />
                    <Form.Control type="hidden" defaultValue={parentDoc} name="parentDoc" />
                  </div>
                </div>
              </div>

              <div className="row g-0">
                <div className="col-lg-12 mb-3">
                  <div className="col-lg-12 position-relative">
                    <div className="eDocTabsContainer">
                      <ul className="nav nav-tabs swiftCloudTabs " role="tablist">
                        <li className="nav-item" role="presentation">
                          <a className="nav-link active tabDocEdit">
                            <i className="bi bi-pencil-fill" />
                            <span>Edit</span>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link tabDocView">
                            <i className="bi bi-file-earmark-text-fill" />
                            <span>Preview</span>
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link btnDocPublished" href="#">
                            <i className="bi bi-globe" />
                            <span>Published</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-12 mb-3 d-none" id="doc_view_panel">
                      <div className="accordion-item">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-lg-12 text-center">Frontend view goes here...</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3" id="editor_panel">
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <Accordion.Item eventKey="0" className="panel panel-white">
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="doc_data">
                                  <CodeEditor
                                    value={code}
                                    language="js"
                                    placeholder="Please enter JS code."
                                    onChange={evn => setCode(evn.target.value)}
                                    padding={15}
                                    style={{
                                      fontSize: 12,
                                      backgroundColor: "#f5f5f5",
                                      fontFamily:
                                        "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>

                    <InstallationOptions
                      driveType={driveType}
                      ownerId={ownerId}
                      driveId={driveId}
                      showInstallationPanel={states.showInstallationPanel}
                    />

                    <MoreOptions
                      tags={tags}
                      setTags={setTags}
                      tempTags={tempTags}
                      setTempTags={setTempTags}
                      docPrivacy={docPrivacy}
                      setDocPrivacy={setDocPrivacy}
                      whoCanModify={whoCanModify}
                      setWhoCanModify={setWhoCanModify}
                      privacyPassword={privacyPassword}
                      whoCanCopy={whoCanCopy}
                      setWhoCanCopy={setWhoCanCopy}
                      showMoreDetailsPanel={states.showMoreDetailsPanel}
                    />

                    <PanelToggle states={states} setStates={setStates} />

                    <div className="col-lg-12 text-center footer-action">
                      <div className="btn-group btnDropdownWithSeparater">
                        <button type="submit" className="btn btn-primary btn-lg">
                          <i className="bi bi-check2" /> Save
                          <i className={saveLoading ? "spinner-border spinner-border-sm" : ""} />
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-files" /> Save as Copy
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-subtract" /> Clone a Template
                            </a>
                          </li>
                        </ul>
                      </div>{" "}
                      <a
                        href={`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/${driveType}/${fileUrl}`}
                        type="button"
                        className="btn btn-secondary btn-lg"
                      >
                        <i className="bi bi-box-arrow-up-right" /> Preview
                      </a>{" "}
                      <button type="button" className="btn btn-default btn-lg">
                        <i className="bi bi-cloud-arrow-up-fill" /> Publish
                      </button>{" "}
                      <button type="button" className="btn btn-default btn-lg" onClick={aimoModalShow}>
                        <i className="bi bi-robot" title="Modify this with AImo" />
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-default btn-lg btnOpenChatDock"
                        data-bs-toggle="tooltip"
                        data-bs-title="History"
                      >
                        <i className="bi bi-arrow-counterclockwise" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AimoModal aimoShow={aimoShow} setAimoShow={setAimoShow} />
      </Form>
    </Layout>
  )
}
export default Detail
