import React from "react"
import Accordion from "react-bootstrap/Accordion"

import Privacy from "../Doc/MoreOptions/privacy"
import Tags from "../Doc/MoreOptions/tags"

import "../../../css/bootstrap-tagsinput.css"

const MoreOptions = ({
  tags,
  setTags,
  tempTags,
  setTempTags,
  docPrivacy,
  setDocPrivacy,
  whoCanModify,
  setWhoCanModify,
  privacyPassword,
  whoCanCopy,
  setWhoCanCopy,
  showMoreDetailsPanel,
}) => {
  return (
    <div className={`col-lg-12 mb-3${!showMoreDetailsPanel ? " d-none" : ""}`} id="more_panel">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="panel panel-grey">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-three-dots" />
            </span>
            <span className="panel-heading-title">More Options</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-tags-fill" /> Tags
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
                  </div>
                </div>
                <div className="col-lg-12 mb-3 docPrivacyContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-lock-fill" /> Privacy & Permissions
                    </strong>
                  </div>
                  <Privacy
                    docPrivacy={docPrivacy}
                    setDocPrivacy={setDocPrivacy}
                    whoCanModify={whoCanModify}
                    setWhoCanModify={setWhoCanModify}
                    privacyPassword={privacyPassword}
                    whoCanCopy={whoCanCopy}
                    setWhoCanCopy={setWhoCanCopy}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-globe" /> Language
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <select name="Language" id="Language" className="form-select">
                        <option value="jsx">Language: jsx</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default MoreOptions
