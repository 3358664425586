import * as React from "react"
import { useState } from "react"
import Modal from "react-bootstrap/Modal"

const AimoModal = ({ aimoShow, setAimoShow }) => {
  const [query, setQuery] = useState("")
  const handleClose = () => {
    setAimoShow(false)
  }
  const handleSubmit = event => {
    if (query) {
      console.log("submit")
      setAimoShow(false)
    }
  }

  return (
    <Modal show={aimoShow} onHide={handleClose}>
      <Modal.Header>
        <h1 className="modal-title fs-5">
          <i className="bi bi-robot" /> Modify this with AImo
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 row align-items-center">
          <label className="col-sm-6 col-form-label fs-5">What do you need done?</label>
          <div className="col-sm-6">
            <select className="form-select">
              <option value="ChatGPT">ChatGPT</option>
              <option value="Google Bard">Google Bard</option>
              <option value="Midjourney">Midjourney</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <textarea
              className="form-control"
              rows="8"
              defaultValue={"Please convert this to react"}
              onChange={e => {
                setQuery(e.target.value)
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={handleClose}>
          <i className="bi bi-x-lg" /> Close
        </button>
        <button
          type="button"
          className="btn btn-primary btnAutoGenerateWithAImo"
          onClick={() => handleSubmit()}
        >
          <i className="bi bi-robot" /> Request
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AimoModal
